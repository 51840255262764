import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      admin: {},
      listData: [],
      tab: "editable"
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("token");
    if (token) {
      this.verifyToken();
      this.fetchList();
    } else {
      this.props.history.push("/login");
    }
  }

  verifyToken = () => {
    let url = `https://list-api.gubb.net/admin/verify`;
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: localStorage.getItem("token")
      })
    };
    fetch(url, requestOptions)
      .then(data => data.json())
      .then(res => {
        if (res.status === 200) {
          this.setState({
            admin: res.data
          });
        } else {
          this.props.history.push("/login");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  fetchList = () => {
    let url = `https://list-api.gubb.net/list/all`;
    let requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token")
      }
    };
    fetch(url, requestOptions)
      .then(data => data.json())
      .then(res => {
        console.log(res);
        this.setState({
          listData: res.data
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  toggleTabs = tab => {
    if (tab === "editable") {
      this.setState({
        tab: "editable"
      });
    } else if (tab === "read-only") {
      this.setState({
        tab: "read-only"
      });
    }
  };

  logOut = () => {
    localStorage.removeItem("token");
    this.props.history.push("/login");
  };

  render() {
    return (
      <div className="admin">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
          <div class="container">
            <a class="navbar-brand" href="#">
              Adminpanel
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <form class="form-inline my-2 my-lg-0">
              <button
                class="btn btn-outline-danger my-2 my-sm-0"
                type="submit"
                onClick={() => this.logOut()}
              >
                Logout
              </button>
            </form>
          </div>
        </nav>

        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <h1 class="my-4"></h1>
              <div class="list-group">
                <li
                  class="list-group-item"
                  onClick={() => this.toggleTabs("editable")}
                >
                  List URL(editable)
                </li>
                <li
                  class="list-group-item"
                  onClick={() => this.toggleTabs("read-only")}
                >
                  List URL(read only)
                </li>
              </div>
            </div>

            <div class="col-lg-9">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">List URL</th>
                  </tr>
                </thead>
                {this.state.tab === "editable" ? (
                  <tbody>
                    {this.state.listData.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{`https://list.gubb.net/${item.editId}`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : this.state.tab === "read-only" ? (
                  <tbody>
                    {this.state.listData.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{`https://list.gubb.net/${item.viewId}`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : null}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Admin);
