import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      message: ""
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("token");
    if (token) {
      this.verifyToken();
    }
  }

  verifyToken = () => {
    let url = `https://list-api.gubb.net/admin/verify`;
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        token: localStorage.getItem("token")
      })
    };
    fetch(url, requestOptions)
      .then(data => data.json())
      .then(res => {
        if (res.status === 200) {
          this.props.history.push("/admin");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  validateCredentials = () => {
    let url = `https://list-api.gubb.net/login`;
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password
      })
    };
    fetch(url, requestOptions)
      .then(data => data.json())
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          console.log(res.data);
          this.setState({
            message: "You are logging in"
          });
          localStorage.setItem("token", res.data.token);
          this.props.history.push("/admin");
        } else {
          this.setState({
            message: "Invalid credentials"
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    return (
      <div className="login">
        <div class="container-fluid">
          <div class="row no-gutter">
            <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
            <div class="col-md-8 col-lg-6">
              <div class="login d-flex align-items-center py-5">
                <div class="container">
                  <div class="row">
                    <div class="col-md-9 col-lg-8 mx-auto">
                      <h3 class="login-heading mb-4">Welcome back!</h3>
                      <form>
                        <div class="form-label-group">
                          <input
                            type="email"
                            name="email"
                            class="form-control"
                            // placeholder="Email address"
                            required
                            autofocus
                            value={this.state.email}
                            onChange={e => this.handleInput(e)}
                          />
                          <label for="inputEmail">Email address</label>
                        </div>

                        <div class="form-label-group">
                          <input
                            type="password"
                            name="password"
                            class="form-control"
                            // placeholder="Password"
                            value={this.state.password}
                            onChange={e => this.handleInput(e)}
                            required
                          />
                          <label for="inputPassword">Password</label>
                        </div>
                        <div
                          class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                          onClick={() => this.validateCredentials()}
                        >
                          Sign in
                        </div>
                      </form>
                      <div className="message">{this.state.message}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Admin);
