import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";
import moment from "moment";

class View extends Component {
  constructor() {
    super();
    this.state = {
      listItems: []
    };
  }

  componentDidMount() {
    this.getListItem();
  }

  getListItem = viewId => {
    let url = `https://list-api.gubb.net/list/view/${this.props.match.params.viewId}`;
    let requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(url, requestOptions)
      .then(data => data.json())
      .then(res => {
        this.setState({
          listItems: res.data.items
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  bookmarkAlert() {
    alert("Press Ctrl + D to bookmark this page");
  }

  render() {
    return (
      <div className="view">
        <div className="top-block">
          <div className="list">
            {this.state.listItems.map(listItem => {
              return (
                <div className="item">
                  <div className="check-box">
                    {listItem.completed ? (
                      <img src="/assets/images/check.png" />
                    ) : (
                      <img src="/assets/images/uncheck.png" />
                    )}
                  </div>
                  <div className="value">
                    <div className="name">{listItem.text}</div>
                  </div>
                </div>
              );
            })}
          </div>

          <div style={{ display: "none" }}>
            <div
              className="list"
              ref={el => (this.componentRef = el)}
              style={{ padding: "5rem" }}
            >
              {this.state.listItems.map(listItem => {
                return (
                  <div
                    className="item"
                    style={{ display: "flex", margin: "2rem 0" }}
                  >
                    <div className="check-box">
                      {listItem.completed ? (
                        <img
                          src="/assets/images/check.png"
                          style={{ width: "3rem" }}
                        />
                      ) : (
                        <img
                          src="/assets/images/uncheck.png"
                          style={{ width: "3rem" }}
                        />
                      )}
                    </div>
                    <div
                      className="value"
                      style={{
                        fontSize: "3em",
                        marginTop: "-1rem",
                        marginLeft: "1rem"
                      }}
                    >
                      <div className="name">{listItem.text}</div>
                      <div className="controls" style={{ fontSize: ".5em" }}>
                        <div className="timestamps">
                          Created on{" "}
                          {moment(listItem.timestamps.updatedAt).format(
                            "h:mm A MMMM Do, YYYY"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="options">
            <div className="left">
              <div className="item">
                <ReactToPrint
                  trigger={() => <div>Print</div>}
                  content={() => this.componentRef}
                  copyStyles={true}
                />
              </div>
              <div className="separation">|</div>
              <div className="item">
                <a
                  href={`mailto:?subject=My%20List&body=Check%20out%20https://list.gubb.net/${this.props.match.params.viewId}`}
                >
                  Email
                </a>
              </div>
              <div className="separation">|</div>

              <div className="item" onClick={() => this.bookmarkAlert()}>
                Bookmark
              </div>
              <div className="separation">|</div>

              <div className="item">
                <a href="https://list.gubb.net/">List maker</a>
              </div>
            </div>
            <div className="right"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(View);
