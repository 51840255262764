import React, { Component } from "react";
import List from "./List";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      editId: "",
      viewId: "",
      newItem: "",
      listItems: [],
      sectionDisplay: "none"
    };
  }

  componentDidMount() {
    if (this.props.location.hash) {
      this.getListItem(this.props.location.hash.substring(1));
      this.setState({
        editId: this.props.location.hash.substring(1)
      });
    } else {
      this.createNewListDocument();
    }
  }

  createNew = () => {
    this.props.history.push("/");
    window.location.reload();
  };

  createNewListDocument = () => {
    fetch(`https://list-api.gubb.net/list/new`)
      .then(data => data.json())
      .then(res => {
        this.setState({
          editId: res.data.editId,
          viewId: res.data.viewId
        });
        this.props.history.push(`#${res.data.editId}`);
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleAddNewInput = e => {
    this.setState({
      newItem: e.target.value
    });
  };

  getListItem = editId => {
    let url = `https://list-api.gubb.net/list/${editId}`;
    let requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(url, requestOptions)
      .then(data => data.json())
      .then(res => {
        this.setState({
          listItems: res.data.items,
          viewId: res.data.viewId
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  addListItem = () => {
    let url = `https://list-api.gubb.net/list/${this.state.editId}`;
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        text: this.state.newItem
      })
    };
    fetch(url, requestOptions)
      .then(data => data.json())
      .then(res => {
        this.setState({
          listItems: res.data.items,
          newItem: ""
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleUpdate = items => {
    console.log(this.state);
    let url = `https://list-api.gubb.net/list/${this.state.editId}`;
    console.log("Url", url);
    let requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        items: items
      })
    };
    fetch(url, requestOptions)
      .then(data => data.json())
      .then(res => {
        this.setState({
          listItems: res.data.items,
          newItem: ""
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  toggleSection = () => {
    this.setState({
      sectionDisplay:
        this.state.sectionDisplay === "block"
          ? "none"
          : this.state.sectionDisplay === "none"
          ? "block"
          : null
    });
  };

  bookmarkAlert() {
    alert("Press Ctrl + D to bookmark this page");
  }

  render() {
    let helpDoc = `<h2>How does Gubb List work?</h2>


    <p>
    When you first access our site, you will notice that a unique URL is generated for you to access or share your list. Your list is ready to use!
    </p>
    <p>
    Keep the list URL in a safe place and bookmark it, as there is no other way to get this URL once you close out of your browser.
    </p>
    <p>
    Your personal URL to the list you created can be shared via email, social media and other methods. Anyone who has this URL can make changes to your list. If you do not want anyone to make changes to the list, there is an option to share the list as read only.
    </p>
    <p>
    <strong>There are several things you can do on your list page</strong>
    </p>
    <ul>
    
    <li>Add new items
    
    <li>Edit existing items
    
    <li>Re-order items
    
    <li>Check off items for competition
    
    <li>Un-check items that are not read to be completed
    
    <li>Delete items that are not required
    </li>
    </ul>
    <p>
    Note: Don't put sensitive information on your list!
    </p>
    <p>
    If you have any further questions, please send an email to <a href="mailto:support@gubb.net">support@gubb.net</a> and we will be happy to help you. 
    </p>
    <p>
    <strong>Make sure to check our <a href="https://www.gubb.net/">Gubb.net</a> Blog for Productive Tips and Hacks.</strong>
    </p>`;
    return (
      <div className="home">
        <div className="top-block">
          <div className="options">
            <div className="left">
              <div className="item">
                <ReactToPrint
                  trigger={() => <div>Print</div>}
                  content={() => this.componentRef}
                  copyStyles={true}
                />
              </div>
              <div className="separation">|</div>
              <div className="item">
                <a
                  href={`mailto:?subject=My%20List&body=Check%20out%20https://list.gubb.net/${this.state.editId}`}
                >
                  Email
                </a>
              </div>
              <div className="separation">|</div>

              <div className="item" onClick={() => this.bookmarkAlert()}>
                Bookmark
              </div>
              <div className="separation">|</div>

              <div className="item">
                <a
                  href={`https://list.gubb.net/view/${this.state.viewId}`}
                  target="_blank"
                  rel="nofollow"
                >
                  Read Only
                </a>
              </div>
              <div className="separation">|</div>

              <div className="item" onClick={() => this.createNew()}>
                New
              </div>
            </div>
            <div className="right">
              <div className="item">
                Your URL is{" "}
                <a
                  href={`https://list.gubb.net#${this.state.editId}`}
                  rel="nofollow"
                >
                  https://list.gubb.net#{this.state.editId}
                </a>
                - Don't lose it!
              </div>
            </div>
          </div>
          <div className="input-box">
            <input
              type="text"
              placeholder="Add new item"
              value={this.state.newItem}
              onChange={e => this.handleAddNewInput(e)}
              className="form-control"
            />
            <div className="add-button" onClick={() => this.addListItem()}>
              <img src="/assets/images/plus.png" />
            </div>
          </div>
          <div className="list">
            <List
              listItems={this.state.listItems && this.state.listItems}
              handleUpdate={items => this.handleUpdate(items)}
            />
          </div>
          <div style={{ display: "none" }}>
            <div
              className="list"
              ref={el => (this.componentRef = el)}
              style={{ padding: "5rem" }}
            >
              {this.state.listItems &&
                this.state.listItems.map(listItem => {
                  return (
                    <div
                      className="item"
                      style={{ display: "flex", margin: "2rem 0" }}
                    >
                      <div className="check-box">
                        {listItem.completed ? (
                          <img
                            src="/assets/images/check.png"
                            style={{ width: "3rem" }}
                          />
                        ) : (
                          <img
                            src="/assets/images/uncheck.png"
                            style={{ width: "3rem" }}
                          />
                        )}
                      </div>
                      <div
                        className="value"
                        style={{
                          fontSize: "3em",
                          marginTop: "-1rem",
                          marginLeft: "1rem"
                        }}
                      >
                        <div className="name">{listItem.text}</div>
                        <div className="controls" style={{ fontSize: ".5em" }}>
                          <div className="timestamps">
                            Created on{" "}
                            {moment(listItem.timestamps.updatedAt).format(
                              "h:mm A MMMM Do, YYYY"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="help-section">
            <div className="help" onClick={() => this.toggleSection()}>
              Help?
            </div>
            <div
              className="expanded"
              style={{ display: this.state.sectionDisplay }}
            >
              {ReactHtmlParser(helpDoc)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
