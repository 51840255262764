import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home, View, Login, Admin } from "./components";
import "./assets/scss/_style.scss";

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/" exact component={() => <Home />} />
            <Route path="/view/:viewId" exact component={() => <View />} />
            <Route path="/login" exact component={() => <Login />} />
            <Route path="/admin" exact component={() => <Admin />} />
          </Switch>
        </Router>
      </div>
    );
  }
}
