import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";

const getItems = count => {
  return this.props.listItems;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  background: isDragging ? "#f0f0f0" : "ghostwhite",

  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "ghostwhite" : "ghostwhite",
  padding: grid,
  width: 250
});

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      editItem: {}
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  componentDidMount() {
    this.setState({
      items: this.props.listItems
    });
  }
  componentDidUpdate(preProps) {
    if (preProps.listItems !== this.props.listItems) {
      this.setState({
        items: this.props.listItems
      });
    }
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    });
    this.props.handleUpdate(items);
  }

  handleCheck = selecteditem => {
    this.state.items.map(item => {
      if (item.id === selecteditem.id) item.completed = !item.completed;
    });
    this.props.handleUpdate(this.state.items);
  };

  editItem = selecteditem => {
    this.setState({
      editItem: {
        ...this.state.editItem,
        editing: true,
        id: selecteditem.id,
        value: selecteditem.text
      }
    });
  };

  deleteItem = selecteditem => {
    this.state.items.map((item, index) => {
      if (item.id === selecteditem.id) {
        this.state.items.splice(index, 1);
        console.log(this.state.items);
      }
    });
    this.props.handleUpdate(this.state.items);
  };

  handleChangeEditValue = e => {
    this.setState({
      editItem: {
        ...this.state.editItem,
        value: e.target.value
      }
    });
  };

  saveEditValue = selecteditem => {
    this.state.items.map(item => {
      if (item.id === selecteditem.id) {
        item.text = this.state.editItem.value;
      }
    });
    this.props.handleUpdate(this.state.items);
    this.setState({
      editItem: {
        ...this.state.editItem,
        editing: false
      }
    });
  };
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="list"
            >
              {this.state.items &&
                this.state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className="item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="check-box">
                          {item.completed ? (
                            <img
                              src="/assets/images/check.png"
                              onClick={() => this.handleCheck(item)}
                              style={{ width: "1.5rem" }}
                            />
                          ) : (
                            <img
                              src="/assets/images/uncheck.png"
                              onClick={() => this.handleCheck(item)}
                              style={{ width: "1.5rem" }}
                            />
                          )}
                        </div>
                        <div className="value">
                          {this.state.editItem.editing &&
                          this.state.editItem.id === item.id ? (
                            <div className="input-box">
                              <input
                                className="form-control"
                                type="text"
                                value={this.state.editItem.value}
                                onChange={e => this.handleChangeEditValue(e)}
                              />
                              <button
                                className="btn btn-success"
                                onClick={() => this.saveEditValue(item)}
                              >
                                OK
                              </button>
                            </div>
                          ) : (
                            <div className="name">{item.text}</div>
                          )}

                          <div className="controls">
                            <div className="timestamps">
                              Created on{" "}
                              {moment(item.timestamps.updatedAt).format(
                                "h:mm A MMMM Do, YYYY"
                              )}
                            </div>
                            <div className="separation">|</div>
                            <div
                              className="edit"
                              onClick={() => this.editItem(item)}
                            >
                              Edit
                            </div>
                            <div className="separation">|</div>
                            <div
                              className="delete"
                              onClick={() => this.deleteItem(item)}
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
